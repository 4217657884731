<template>
  <el-dialog
    v-model="show"
    width="30%"
    align-center
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    class="elv-match-set-dialog"
    @close="onCloseDialog"
  >
    <template #header="{ titleId }">
      <h4 :id="titleId" class="elv-match-set-dialog-header__title">
        {{ props.model === 'add' ? t('title.addMatchSet') : t('title.editMatchSet') }}
      </h4>
    </template>
    <div v-loading="dialogLoading" class="elv-match-set-dialog-container">
      <el-form ref="matchSetFormRef" :model="matchSetForm" label-position="top" :rules="rules" @submit.prevent>
        <el-form-item :label="t('common.description')" prop="description">
          <el-input v-model="matchSetForm.description" :placeholder="t('message.pleaseInput')"
        /></el-form-item>
        <el-form-item :label="t('report.Account')" prop="chartOfAccountId">
          <SingleChoiceSelect
            v-model="matchSetForm.chartOfAccountId"
            filterable
            :disabled="props.model === 'edit'"
            :options="chartOfAccountOptions"
            width="572px"
          />
        </el-form-item>
        <el-form-item :label="t('common.direction')" prop="direction">
          <el-select
            v-model="matchSetForm.direction"
            :placeholder="t('common.direction')"
            :disabled="props.model === 'edit'"
            :suffix-icon="SelectSuffixIcon"
          >
            <el-option :label="t('common.debit')" value="IN" />
            <el-option :label="t('common.credit')" value="OUT" />
          </el-select>
        </el-form-item>
      </el-form>
    </div>

    <template #footer>
      <elv-button
        type="primary"
        :disabled="disabledSave"
        :loading="saveLoading"
        round
        width="100"
        height="44"
        @click="onSaveMatchSet"
        >{{ t('button.save') }}</elv-button
      >
    </template>
  </el-dialog>
</template>

<script setup lang="ts">
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { ElMessage } from 'element-plus'
import timezone from 'dayjs/plugin/timezone'
import ReconciliationApi from '@/api/ReconciliationApi'
import { useEntityStore } from '@/stores/modules/entity'
import type { FormInstance, FormRules } from 'element-plus'
import SelectSuffixIcon from '@/components/Project/SelectSuffixIcon.vue'
import SingleChoiceSelect from '@/components/Base/SingleChoiceSelect.vue'

const props = defineProps({
  model: {
    type: String,
    default: 'add' // 'add' | 'edit'
  },
  currentData: {
    type: Object,
    default: () => {
      return {}
    }
  }
})

dayjs.extend(utc)
dayjs.extend(timezone)
const { t } = useI18n()
const route = useRoute()
const show = defineModel('show', { type: Boolean, required: true })

const entityStore = useEntityStore()
const emit = defineEmits(['onResetList'])
const dialogLoading = ref(false)
const saveLoading = ref(false)
const matchSetFormRef = useTemplateRef<FormInstance>('matchSetFormRef')
const matchSetForm = reactive({
  description: '',
  chartOfAccountId: '',
  direction: ''
})

const rules = reactive<FormRules>({
  description: [
    {
      required: true,
      trigger: 'blur'
    }
  ],
  chartOfAccountId: [
    {
      required: true,
      trigger: 'blur'
    }
  ],
  direction: [
    {
      required: true,
      trigger: 'blur'
    }
  ]
})

const entityId = computed(() => {
  return String(route.params?.entityId as string)
})

const disabledSave = computed(() => {
  return !matchSetForm.description || !matchSetForm.chartOfAccountId || !matchSetForm.direction
})

const chartOfAccountOptions = computed(() => {
  return entityStore.chartOfAccountOriginList?.[0]?.list.map((item) => {
    return {
      value: item.chartOfAccountId,
      label: item.name
    }
  })
})

const onCloseDialog = () => {
  matchSetFormRef.value?.resetFields()
  matchSetForm.chartOfAccountId = ''
  matchSetForm.description = ''
  matchSetForm.direction = ''
}

const onAddMatchSet = async () => {
  try {
    saveLoading.value = true
    await ReconciliationApi.createReconciliationSet(entityId.value, matchSetForm)
    ElMessage.success(t('message.success'))
    emit('onResetList')
    show.value = false
  } catch (error: any) {
    console.log(error)
    ElMessage.error(error.message)
  } finally {
    saveLoading.value = false
  }
}

const onEditMatchSet = async () => {
  try {
    saveLoading.value = true
    await ReconciliationApi.updateReconciliationSet(entityId.value, props?.currentData?.reconciliationSetId, {
      description: matchSetForm.description
    })
    ElMessage.success(t('message.success'))
    emit('onResetList')
    show.value = false
  } catch (error: any) {
    console.log(error)
    ElMessage.error(error.message)
  } finally {
    saveLoading.value = false
  }
}

const onSaveMatchSet = () => {
  if (props.model === 'add') {
    onAddMatchSet()
  } else {
    onEditMatchSet()
  }
}

watch(
  [() => show.value, () => props?.currentData],
  async () => {
    if (show.value && props.model === 'edit' && props?.currentData?.reconciliationSetId) {
      matchSetForm.chartOfAccountId = props?.currentData?.chartOfAccountId ?? ''
      matchSetForm.description = props?.currentData?.description ?? ''
      matchSetForm.direction = props?.currentData?.direction ?? ''
    }
  },
  { immediate: true }
)
</script>

<style lang="scss">
.elv-match-set-dialog {
  width: 620px;
  box-shadow:
    0px 2px 6px rgba(0, 0, 0, 0.05),
    0px 0px 1px rgba(0, 0, 0, 0.3);
  border-radius: 6px;

  .el-dialog__header {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    height: 54px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 700;
    margin: 0;
    padding: 0;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-start;
    color: #0e0f11;
    padding-left: 24px;
    border-bottom: 1px solid #edf0f3;

    .elv-match-set-dialog-header__title {
      font-family: 'Plus Jakarta Sans';
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #0e0f11;
      margin: 0;
    }
  }

  .el-dialog__body {
    padding: 18px 24px 6px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    .elv-match-set-dialog-container {
      width: 100%;
    }

    .elv-match-set-dialog-content {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-bottom: 12px;
    }
  }

  .el-dialog__footer {
    padding: 20px 0px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
